import 'bootstrap';
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
import Rails from '@rails/ujs';
//import * as ActiveStorage from '@rails/activestorage';
//import 'channels';

// Note this is important!

flatpickr('.datepicker', {
  locale: French,
  altInput: true,
  altFormat: 'j F Y',
  minDate: 'today',
});

flatpickr('.datepicker-open', {
  locale: French,
  altInput: true,
  altFormat: 'j F Y',
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

//ActiveStorage.start();
Rails.start();
